/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum BilagTilstand {
  afventer = "afventer",
  afvist_mime_type = "afvist_mime_type",
  afvist_stoerrelse = "afvist_stoerrelse",
  afvist_virus = "afvist_virus",
  fejl = "fejl",
  godkendt = "godkendt",
  valideres = "valideres",
}

export enum DokumentGruppe {
  Andet = "Andet",
  Bilag = "Bilag",
  Bilag_til_indsigelse = "Bilag_til_indsigelse",
  Bilag_til_klage = "Bilag_til_klage",
  Bilag_til_motiveret_indstilling = "Bilag_til_motiveret_indstilling",
  Bortfaldsbrev = "Bortfaldsbrev",
  Datagrundlag = "Datagrundlag",
  Datagrundlag_orienteringsbrev = "Datagrundlag_orienteringsbrev",
  Deklarationsmeddelelse = "Deklarationsmeddelelse",
  GIS = "GIS",
  Gendeklarationsmeddelelse = "Gendeklarationsmeddelelse",
  Gentilbagebetalingsafgoerelse = "Gentilbagebetalingsafgoerelse",
  Gentilbagebetalingsafgoerelse_genberegning = "Gentilbagebetalingsafgoerelse_genberegning",
  Gentilbagebetalingsafgoerelse_genberegning_alene = "Gentilbagebetalingsafgoerelse_genberegning_alene",
  Gentilbagebetalingsgrundlag = "Gentilbagebetalingsgrundlag",
  Gentilbagebetalingsgrundlag_genberegning = "Gentilbagebetalingsgrundlag_genberegning",
  Grundvaerdibilag = "Grundvaerdibilag",
  Grundvaerdibilag_etageboligbyggeri = "Grundvaerdibilag_etageboligbyggeri",
  Grundvaerdibilag_grund_under_BFG = "Grundvaerdibilag_grund_under_BFG",
  Grundvaerdibilag_komb_pcl_som = "Grundvaerdibilag_komb_pcl_som",
  Grundvaerdibilag_saerlige_ejl = "Grundvaerdibilag_saerlige_ejl",
  Historiske_vurderinger = "Historiske_vurderinger",
  Ikke_omfattet_af_tilbagebetalingsordningen_og_klagefristbrev = "Ikke_omfattet_af_tilbagebetalingsordningen_og_klagefristbrev",
  Indsigelse = "Indsigelse",
  Indsigelsesgrundlag = "Indsigelsesgrundlag",
  Juridisk_kategori = "Juridisk_kategori",
  Klage = "Klage",
  Klageformular = "Klageformular",
  Klagefristbrev = "Klagefristbrev",
  Kompensationsafgoerelse = "Kompensationsafgoerelse",
  Kun_klagefristbrev = "Kun_klagefristbrev",
  Kvitteringssvar = "Kvitteringssvar",
  Motiveret_indstilling = "Motiveret_indstilling",
  Offentlig_vurdering = "Offentlig_vurdering",
  Opdatering_af_sagsparter = "Opdatering_af_sagsparter",
  Orienteringsbrev = "Orienteringsbrev",
  Planscenariebilag_flere_deljordstykker_del2_pcl_som = "Planscenariebilag_flere_deljordstykker_del2_pcl_som",
  Revurdering = "Revurdering",
  Styringskoder = "Styringskoder",
  Supplerende_materiale_til_klagen = "Supplerende_materiale_til_klagen",
  Tilbagebetalingsafgoerelse = "Tilbagebetalingsafgoerelse",
  Tilbagebetalingsafgoerelse_hoveddokument = "Tilbagebetalingsafgoerelse_hoveddokument",
  Tilbagebetalingsgrundlag = "Tilbagebetalingsgrundlag",
  Tilbagebetalingstilbud = "Tilbagebetalingstilbud",
  Tilbagekaldsbrev = "Tilbagekaldsbrev",
  Vurderingsaarsafgoerelse = "Vurderingsaarsafgoerelse",
  Vurderingsforslag = "Vurderingsforslag",
  Vurderingsmeddelelse = "Vurderingsmeddelelse",
  e_Boks = "e_Boks",
}

export enum IndsendelseTilstand {
  indsendt = "indsendt",
  kladde = "kladde",
  slettet = "slettet",
}

export enum IndsendelseType {
  bbr = "bbr",
  indsigelse = "indsigelse",
  kontaktformular = "kontaktformular",
  ret_bbr = "ret_bbr",
  sanst_klagesagsnotifikation = "sanst_klagesagsnotifikation",
  sanst_klagesagsopdatering = "sanst_klagesagsopdatering",
}

export enum IndsigelsesperiodeLukketAarsag {
  deklarationsperiode_ikke_startet = "deklarationsperiode_ikke_startet",
  deklarationsperiode_udloebet = "deklarationsperiode_udloebet",
  ejerskabsperiode_udenfor_deklarationsperiode = "ejerskabsperiode_udenfor_deklarationsperiode",
  kun_laeseadgang = "kun_laeseadgang",
}

export enum IndsigelsesperiodeTilstand {
  aaben = "aaben",
  lukket = "lukket",
}

export enum Order {
  asc = "asc",
  desc = "desc",
}

export enum Privilegie {
  read = "read",
  write = "write",
}

export enum RepraesentantRolle {
  advokatforhold = "advokatforhold",
  bobestyrer = "bobestyrer",
  ejendomsadministration = "ejendomsadministration",
  ejerskabsadministrator = "ejerskabsadministrator",
  foraeldremyndighedsindehaver = "foraeldremyndighedsindehaver",
  fuldmagt = "fuldmagt",
  kurator = "kurator",
  likvidator = "likvidator",
  rekonstruktoer = "rekonstruktoer",
  revisor = "revisor",
  vaergemaal = "vaergemaal",
}

export enum SagspersonType {
  cpr = "cpr",
  cvr = "cvr",
  id = "id",
}

export enum VurderingsejendommeSorting {
  latest_dokument_activity = "latest_dokument_activity",
}

export interface BilagInput {
  mime_type: string;
  navn: string;
  stoerrelse: number;
}

export interface VurderingsejendommePagingInput {
  limit: number;
  offset: number;
  order: Order;
  sorting: VurderingsejendommeSorting;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
